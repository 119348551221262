<template>
  <div class="main-view-padding has-header">
    <!--div class="header main" v-header-position-lock>
      <div class="align-left">
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons"/>
      </div>
      <div class="align-right">
      </div>
    </div-->
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      BMD-ID Import
    </div>

    <div v-if="loading" class="loading-md bg">
      <i class="fas fa-spinner"></i>
      <br/>
      <div class="loading-text">
        Lade Dateien
      </div>
    </div>

    <template v-else>
      <FileUploadBox @upload-success="onUploadSuccess"></FileUploadBox>
      <br>
      <b-table striped hover :items="fileTableItems" :fields="fileTableFields" class="files-table">
        <template #cell(actions)="data">
          <buttonc :label="matchingResults[data.item.originalFile.id] ? 'Erneut überprüfen' : 'Überprüfung starten'"
                   @click="startMatching(data)"></buttonc>
          <buttonc v-if="!!matchingResults[data.item.originalFile.id]"
                   label="Ergebnisse anzeigen"
                   @click="selectedResults = data.item.originalFile.id"></buttonc>
        </template>
        <template #cell(progress)="data">
          <span v-for="progress of [matchingProgresses[data.item.originalFile.id]]"
                :key="(!!progress ? progress.currentStep : '0') + data.item.originalFile.id">
            <template v-if="!!progress">
              Schritt {{ progress.currentStep }}/{{ progress.finalStep }}:
              {{ progress.status }}
            </template>
            <span v-else>–</span>
          </span>
        </template>
      </b-table>

      <hr>

      <div v-if="selectedResults" class="results-container">
        <h4>{{ resultsTitle }}</h4>
        <b-card no-body class="results-card">
          <b-tabs pills card vertical active-nav-item-class="results-tab-active">
            <b-tab v-for="item of [
                  {key: 'fullMatches', label: 'Vollständige Übereinstimmung'},
                  {key: 'partialMatches', label: 'Teilweise Übereinstimmung'},
                  {key: 'onlyInDb', label: 'Nur in Datenbank'},
                  {key: 'onlyInFile', label: 'Nur in Datei'}]"
                   :key="item.key"
                   :ref="item.key + 'Tab'"
                   @click="selectedResultsType = item.key"
            >
              <template #title>
                <div class="d-flex justify-content-between align-items-center results-tab-title">
                  <div class="mr-2">{{ item.label }}</div>
                  <b-badge>{{ matchingResults[selectedResults][item.key].length }}</b-badge>
                </div>
              </template>
              <b-table class="results-table"
                       :items="getResultsTableItems(item.key)"
                       :fields="getResultsTableFields(item.key)">
                <template #cell(partialMatchesActions)="data">
                  <!--<buttonc label="DB-Eintrag aktualisieren" @click="showUpdateDbModal(data)"></buttonc>-->
                  <buttonc label="BMD-ID in DB eintragen" @click="updateBmdIdInDb(data)"></buttonc>
                </template>
                <!--<template #cell(onlyInFileActions)="data">
                  <buttonc label="In DB eintragen" @click="createDbEntry(data)"></buttonc>
                </template>-->
              </b-table>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </template>

    <!-- TODO: a modal where all differences can be edited before updating the db -->
    <b-modal id="updateDbEntryModal" title="BD-Eintrag aktualisieren">
      <template v-if="updateDbModalData">
        {{ updateDbModalData.originalData }}
      </template>
    </b-modal>
  </div>
</template>

<script>
import FileUploadBox from '@/components/employeecontext/FileUploadBox';
import moment from 'moment';

export default {
  name: 'BMDIDImport',
  components: { FileUploadBox },
  data() {
    return {
      loading: true,
      uploadedFiles: [],
      matchingProgresses: {},
      matchingResults: {},
      selectedResults: null,
      selectedResultsType: null,
      updateDbModalData: null,
      updateDbModalUpdateData: null,
    };
  },
  created() {
    this.reload();
  },
  computed: {
    fileTableFields: () => [
      { key: 'fileName', label: 'Dateiname' },
      { key: 'uploadedAt', label: 'Hochgeladen am' },
      { key: 'actions', label: 'Aktionen' },
      { key: 'progress', label: 'Überprüfungsfortschritt' },
    ],
    fileTableItems() {
      return this.uploadedFiles.map(file => ({
        fileName: file.clientOriginalName,
        uploadedAt: moment.unix(file.timestamp).format('DD.MM.YYYY HH:mm'),
        originalFile: { ...file },
      }));
    },
    resultsTitle() {
      if (!this.selectedResults) return '';

      const fileName = this.uploadedFiles.find(f => f.id === this.selectedResults).clientOriginalName;
      return `Unterschiede zwischen "${ fileName }" und Datenbank`;
    },
  },
  methods: {
    async reload() {
      this.loading = true;
      const response = await this.axios.get('/api/sec/file/upload', {
        headers: { Authorization: 'Bearer ' + this.$store.state.jwt },
        params: {
          companyId: this.$store.state.companyId,
          uploadType: 'bmdidcomparison',
          uploadTypeId: '1',
        },
      });
      this.uploadedFiles = response.data.data;
      this.loading = false;
    },

    onUploadSuccess(file, response) {
      console.log(`Uploaded new file '${ file.name }' (ID: ${ response.data.id })`);
      this.reload();
    },

    async getContentOfFile(fileId) {
      const response = await this.axios.get('/api/sec/file/content', {
        headers: { Authorization: 'Bearer ' + this.$store.state.jwt },
        params: {
          companyId: this.$store.state.companyId,
          fileId,
        },
      });
      return response.data;
    },

    async getDBEntriesForCompany() {
      const response = await this.axios.get('/api/sec/employee/coredata', {
        headers: { Authorization: 'Bearer ' + this.$store.state.jwt },
        params: {
          companyId: this.$store.state.companyId,
          basicData: true,
        },
      });
      return response.data.data;
    },

    // results table getters
    getResultsTableFields(key) {
      const result = this.matchingResults[this.selectedResults];
      const tableFields = {
        fullMatches: [
          { key: 'dbId', label: 'DB-ID' },
          { key: 'firstName', label: 'Vorname' },
          { key: 'lastName', label: 'Nachname' },
          { key: 'bmdId', label: 'BMD-ID' },
          { key: 'socialSecurity', label: 'SVNR' },
        ],
        partialMatches: [
          { key: 'dbId', label: 'DB-ID' },
          { key: 'firstName', label: 'Vorname' },
          { key: 'lastName', label: 'Nachname' },
          { key: 'bmdId', label: 'BMD-ID' },
          { key: 'socialSecurity', label: 'SVNR' },
          { key: 'dob', label: 'Geburtsdatum' },
          { key: 'partialMatchesActions', label: 'Aktionen' },
        ],
        onlyInDb: [
          { key: 'id', label: 'ID' },
          { key: 'firstName', label: 'Vorname' },
          { key: 'lastName', label: 'Nachname' },
          { key: 'bmdId', label: 'BMD-ID' },
          { key: 'socialsecurity', label: 'SVNR' },
          { key: 'dob', label: 'Geburtsdatum' },
        ],
        onlyInFile: [
          { key: 'firstName', label: 'Vorname' },
          { key: 'lastName', label: 'Nachname' },
          { key: 'bmdId', label: 'BMD-ID' },
          { key: 'socialSecurity', label: 'SVNR' },
          { key: 'onlyInFileActions', label: 'Aktionen' },
        ],
      };
      return tableFields[key];
    },
    getResultsTableItems(key) {
      const result = this.matchingResults[this.selectedResults];
      const tableItems = {
        fullMatches: () => result.fullMatches.map(match => ({
          dbId: match.dbEntry.id,
          firstName: match.equalFields.firstName,
          lastName: match.equalFields.lastName,
          bmdId: match.equalFields.bmdId,
          socialSecurity: match.fileEntry.socialSecurity,
        })),
        partialMatches: () => result.partialMatches.map(match => {
          const { differences, equalFields } = match;
          const formatValue = key =>
            equalFields[key] || `${ differences.file[key] } (in Datei) / ${ differences.db[key] } (in DB)`;
          return {
            dbId: match.dbEntry.id,
            firstName: formatValue('firstName'),
            lastName: formatValue('lastName'),
            bmdId: formatValue('bmdId'),
            dob: formatValue('dob'),
            socialSecurity: formatValue('socialSecurity'),
            originalData: match,
          };
        }),
        onlyInDb: () => result.onlyInDb,
        onlyInFile: () => result.onlyInFile,
      };
      return tableItems[key]();
    },
    // result table actions
    showUpdateDbModal({ item }) {
      this.updateDbModalData = item;
      this.$bvModal.show('updateDbEntryModal');
    },
    updateDbEntry() {
      console.log('update DB entry:', this.updateDbModalUpdateData);
      this.$bvModal.hide('updateDbEntryModal');
      this.updateDbModalUpdateData = null;
      this.updateDbModalData = null;
    },
    createDbEntry({ item }) {
      console.log('create DB entry:', item);
    },
    async updateBmdIdInDb({ item }) {
      const bmdId = item.originalData.fileEntry.bmdId;
      const userId = item.originalData.dbEntry.id;
      const companyId = item.originalData.dbEntry.companyId;

      try {
        await this.axios.put(
          `/api/sec/employee/coredata/${ userId }`,
          { bmdId, companyId },
          { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } }
        );

        // matches again (could be done more efficient as below, but doesn't guarantee that the data is correct)
        await this.startMatching({ item: { originalFile: { id: this.selectedResults } } });
        // make it more efficient
        /*const newResult = { ...this.matchingResults[this.selectedResults] };
        newResult.partialMatches = newResult.partialMatches.map(match => {
          if (match.dbEntry.id === userId) {
            match = { ...match };
            match.dbEntry.bmdId = bmdId;
            match.equalFields.bmdId = bmdId;
            match.differences.file.bmdId = undefined;
            match.differences.db.bmdId = undefined;
          }
          return match;
        });
        this.$set(this.matchingResults, this.selectedResults, newResult);*/
      } catch (error) {
        this.$bvToast.toast(error.message, {
          appendToast: true,
          autoHideDelay: 5000,
          toaster: 'b-toaster-bottom-center',
          variant: 'danger',
          title: 'Fehler beim Aktualisieren der BMD-ID',
        });
      }
    },

    // matching algorithm functions
    utf8_to_b64(str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    },

    /* b64_to_utf8(str) {
      return decodeURIComponent(escape(window.atob(str)));
    },*/

    /**
     * Levenshtein distance algorithm
     * https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely
     * @param {string} s1
     * @param {string} s2
     * @returns {number}
     */
    similarity(s1, s2) {
      let [ longer, shorter ] = [ s1, s2 ];
      if (s1.length < s2.length)
        [ longer, shorter ] = [ s2, s1 ];

      const longerLength = longer.length;
      if (longerLength === 0) return 1;

      return (longerLength - this._editDistance(longer, shorter)) / longerLength;
    },

    /**
     * https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely
     * @param {string} s1
     * @param {string} s2
     * @returns {number}
     */
    _editDistance(s1, s2) {
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();

      const costs = [];
      for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
          if (i === 0)
            costs[j] = j;
          else {
            if (j > 0) {
              let newValue = costs[j - 1];
              if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                  costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
        if (i > 0)
          costs[s2.length] = lastValue;
      }
      return costs[s2.length];
    },

    matchNames(firstName1, lastName1, firstName2, lastName2) {
      return this.similarity(firstName1 + lastName1, firstName2 + lastName2);
    },

    matchEntries(fileEntries, dbEntries) {
      // console.log(fileEntries);
      // console.log(dbEntries);

      // const resultsTable = {};

      /**
       * Makes accessing easier, when formatting the matches at the end
       * @type {Object.<string, {bmdId: string, firstName: string, lastName: string, socialSecurity: string}>}
       */
      const fileEntriesWithKey = {};

      /**
       * @type {Object.<string, [{percentMatching: number, dbKey: string, id: number, firstName: string, lastName: string, bmdId: number, companyId: number, socialsecurity: string, dob: string}]>}
       */
      const fileEntryHasMatchesInDB = {};
      /**
       * @type {Object.<string, [{percentMatching: number, fileKey: string, firstName: string, lastName: string, bmdId: string, socialSecurity: string}]>}
       */
      const dbEntryHasMatchesInFile = {};

      // Match entries and store them in the maps defined above
      for (const fileEmployee of fileEntries) {
        const {
          bmdId: fileBmdId,
          firstName: fileFirstName,
          lastName: fileLastName,
          socialSecurity: fileSocialSecurity,
        } = fileEmployee;
        // Generate identifier for file entry
        const fileEntryKey = this.utf8_to_b64(fileFirstName + fileLastName);

        // add entry to fileEntriesWithKey
        fileEntriesWithKey[fileEntryKey] = fileEmployee;

        // Testing Table Row
        // const resultsRow = {};

        for (const dbEmployee of dbEntries) {
          // noinspection SpellCheckingInspection
          const {
            bmdId: dbBmdId,
            firstName: dbFirstName,
            lastName: dbLastName,
            socialsecurity: dbSocialSec,
            dob: dbDob,
          } = dbEmployee;
          const percentMatching = this.matchNames(fileFirstName, fileLastName, dbFirstName, dbLastName);

          // Generate identifier for db entry
          const dbEntryKey = this.utf8_to_b64(dbFirstName + dbLastName);

          let fileEntryMatches = fileEntryHasMatchesInDB[fileEntryKey] || [];
          let dbEntryMatches = dbEntryHasMatchesInFile[dbEntryKey] || [];

          // Add entry if match ≥ 0.85
          if (percentMatching >= 0.85) {
            fileEntryMatches = [ ...fileEntryMatches, {
              ...dbEmployee,
              dbKey: dbEntryKey,
              percentMatching,
            } ];
            dbEntryMatches = [ ...dbEntryMatches, {
              ...fileEmployee,
              fileKey: fileEntryKey,
              percentMatching,
            } ];
          }

          fileEntryHasMatchesInDB[fileEntryKey] = fileEntryMatches;
          dbEntryHasMatchesInFile[dbEntryKey] = dbEntryMatches;

          // resultsRow[dbFirstName + ' ' + dbLastName] = percentMatching;
        }

        // resultsTable[fileFirstName + ' ' + fileLastName] = resultsRow;
      }

      // console.table(resultsTable);

      // console.log('fileEntryHasMatchesInDB:', fileEntryHasMatchesInDB);
      // console.log('dbEntryHasMatchesInFile:', dbEntryHasMatchesInFile);

      const onlyInDb = dbEntries.filter(entry => {
        const dbEntryKey = this.utf8_to_b64(entry.firstName + entry.lastName);
        return dbEntryHasMatchesInFile[dbEntryKey].length === 0;
      });
      const onlyInFile = fileEntries.filter(entry => {
        const fileEntryKey = this.utf8_to_b64(entry.firstName + entry.lastName);
        return fileEntryHasMatchesInDB[fileEntryKey].length === 0;
      });

      /**
       * Contains the formatted matches with differences
       * @type {[{percentMatching: number, equalFields: {firstName: string, lastName: string, dob: string, bmdId: string, socialSecurity: string}, differences: {file: {firstName: string, lastName: string, dob: string, bmdId: string, socialSecurity: string}, db: {firstName: string, lastName: string, dob: string, bmdId: string, socialSecurity: string}}, dbEntry, fileEntry}]}
       */
      const matches = [];

      for (const [ key, dbMatches ] of Object.entries(fileEntryHasMatchesInDB)) {
        if (dbMatches.length < 1) continue;

        const fileEntry = fileEntriesWithKey[key];
        const [ fileSocialSec, fileDobShort ] = fileEntry.socialSecurity.split(' ');

        for (const dbEntry of dbMatches) {
          // {percentMatching, dbKey, id, firstName, lastName, bmdId, companyId, socialsecurity, dob}
          const dbDobShort = moment(dbEntry.dob).format('DDMMYY');

          const equalFields = {};
          const differences = {};

          const fieldsToCompare = {
            firstName: [ dbEntry.firstName, fileEntry.firstName ],
            lastName: [ dbEntry.lastName, fileEntry.lastName ],
            bmdId: [ dbEntry.bmdId.toString(), fileEntry.bmdId.toString() ],
            socialSecurity: [ dbEntry.socialsecurity, fileSocialSec ],
            dob: [ dbDobShort, fileDobShort ],
          };

          for (const [ field, values ] of Object.entries(fieldsToCompare)) {
            if (values[0] === values[1])
              equalFields[field] = values[0];
            else {
              differences.db = { ...differences.db, [field]: values[0] };
              differences.file = { ...differences.file, [field]: values[1] };
            }
          }

          matches.push({
            percentMatching: dbEntry.percentMatching,
            equalFields,
            differences,
            fileEntry,
            dbEntry,
          });
        }
      }

      const fullMatches = matches.filter(match => Object.keys(match.differences).length === 0);
      const partialMatches = matches.filter(match => Object.keys(match.differences).length > 0);

      return {
        fullMatches,
        partialMatches,
        onlyInDb,
        onlyInFile,
      };
    },

    async startMatching({ item }) {
      const fileId = item.originalFile.id;
      const finalStep = 4;

      // step 1: getting file contents
      this.$set(this.matchingProgresses, fileId, { status: 'Dateiinhalt abfragen...', currentStep: 1, finalStep });
      let fileContent = await this.getContentOfFile(fileId);
      fileContent = Object.values(fileContent);

      // step 2: getting db entries
      this.$set(this.matchingProgresses, fileId, { status: 'Datenbankinhalt abfragen...', currentStep: 2, finalStep });
      const dbEntries = await this.getDBEntriesForCompany();

      // step 3: match entries
      this.$set(this.matchingProgresses, fileId, { status: 'Mitarbeiter vergleichen...', currentStep: 3, finalStep });
      const results = this.matchEntries(fileContent, dbEntries);
      console.log(results)

      // step 4: finished
      this.$set(this.matchingProgresses, fileId, { status: 'Fertig!', currentStep: finalStep, finalStep });
      this.$set(this.matchingResults, fileId, results);
      this.selectedResults = fileId;

      // selects the latest selectedTab or partialMatches after next refresh
      this.$nextTick(() => {
        const resultsTypeTab = this.$refs[this.selectedResultsType + 'Tab'];
        if (resultsTypeTab) {
          this.selectedResultsType = this.selectedResultsType || 'partialMatches';
          resultsTypeTab[0].activate();
        } else {
          this.selectedResultsType = 'fullMatches';
        }
      });
    },
  },
}
</script>

<style scoped>
.files-table {
  color: var(--contrast-4);
}

.results-container {
  color: var(--contrast-4);
  padding-top: 2rem;
}

.results-card {
  margin-top: 1rem;
  background-color: var(--contrast-1);
  color: var(--contrast-4);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.results-table {
  color: var(--contrast-4);
}

hr {
  border-color: var(--contrast-2);
}
</style>

<style>
.results-tab-active {
  background-color: var(--ml) !important;
  border-radius: 0 !important;
}

.results-tab-title {
  color: var(--ml);
}

.results-tab-active .results-tab-title {
  color: var(--contrast-1) !important;
}

.results-tab-title:hover {
  color: var(--col-red-text);
}
</style>
