<template>
  <div
    class="dropzone"
    :class="{
      'dropzone-active': $refs.upload && $refs.upload.dropActive
    }"
  >
    <h4 v-if="$refs.upload && $refs.upload.dropActive">
      Loslassen zum Upload
    </h4>
    <h4 v-else>Dokumente hier hineinziehen</h4>

    <p>– oder –</p>

    <file-upload
      :drop="true"
      :multiple="true"
      ref="upload"
      @input-file="handleFile"
    >
      <buttonc label="Dateien auswählen" icon="fa-plus"/>
    </file-upload>

    <ul v-if="Object.keys(files).length > 0" class="upload-list">
      <li v-for="(fileData, id) in files" :key="id">
        <span>{{ fileData.f.name }}</span> -
        <span>{{ fileData.f.size | formatSize }}</span>

        <span v-if="fileData.error"> - {{ fileData.error }}</span>

        <p v-if="fileData.sizeWarning">
          <buttonc
            icon="fa-play"
            label="Fortfahren"
            type="green"
            v-on:click="continueSizeCheck(id)"
          />
          <buttonc
            icon="fa-stop"
            label="Abbrechen"
            v-on:click="removeFile(id)"
          />
        </p>

        <bar :min="0" :max="100" :value="fileData.uploadProgress"/>
      </li>
    </ul>
  </div>
</template>

<script>
import buttonc from '../uicomponents/button';
import FileUpload from 'vue-upload-component';
import axios from 'axios';

export default {
  name: 'fileUploadBox',
  components: { FileUpload, buttonc },
  props: {
  },
  data() {
    /**
     * @type {Object.<string,{f: VUFile, error: string, sizeWarning: boolean, uploadProgress: number|null}>} files
     */
    const files = {};
    return { files };
  },
  methods: {
    /**
     * @param {string} fileId
     */
    removeFile(fileId) {
      this.$delete(this.files, fileId);
    },
    /**
     * @param {VUFile|null} newFile
     * @param {VUFile|null} oldFile
     */
    async handleFile(newFile, oldFile) {
      if (!newFile) return;

      // Check file size
      if (newFile.size > 32 * 1000 * 1000) {
        this.files = {
          ...this.files,
          [newFile.id]: {
            f: newFile,
            error: `Upload von ${ newFile.name } fehlgeschlagen, die Datei ist größer als 32MB`,
            sizeWarning: false,
          },
        };
        return;
      }

      this.files = {
        ...this.files,
        [newFile.id]: {
          f: newFile,
          error: null,
          sizeWarning: false,
        },
      };
      this.uploadFile(newFile).catch(error => {
        this.files = {
          ...this.files,
          [newFile.id]: {
            ...this.files[newFile.id],
            error: `Upload von ${ newFile.name } fehlgeschlagen: ${ error.message }`,
          },
        };
      });
    },
    /**
     * @param {VUFile} file
     */
    async uploadFile(file) {
      const fileToUpload = this.files[file.id];
      // const companyGroupId = this.$store.state.companyId;

      const formData = new FormData();
      formData.append('file', file.file, file.name);
      formData.append('companyId', `${ this.$store.state.companyId }`);
      formData.append('uploadType', 'bmdidcomparison');
      formData.append('uploadTypeId', '1');

      try {
        const response = await axios.post('/api/sec/file/upload', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            console.log(
              (progressEvent.loaded / progressEvent.total) * 100 + '%',
            );
            this.$set(
              fileToUpload,
              'uploadProgress',
              (progressEvent.loaded / progressEvent.total) * 100,
            );
          },
        });
        console.log(response.data);
        this.$emit('upload-success', fileToUpload, response);
        // Remove file after upload or error after 5/10s
        /* setTimeout(() => {
          this.$delete(this.files, file.id);
        }, 5 * 1000);*/
      } catch (error) {
        this.$set(
          fileToUpload,
          'error',
          `Upload von ${ file.name } fehlgeschlagen: ${ error.message }`,
        );
        // Remove file when an error occurs after 10s
        setTimeout(() => {
          this.$delete(this.files, file.id);
        }, 10 * 1000);
      }
    },
  },
  filters: {
    formatSize(size) {
      if (size > 1000 * 1000 * 1000 * 1000) {
        return (size / 1000 / 1000 / 1000 / 1000).toFixed(2) + ' TB';
      } else if (size > 1000 * 1000 * 1000) {
        return (size / 1000 / 1000 / 1000).toFixed(2) + ' GB';
      } else if (size > 1000 * 1000) {
        return (size / 1000 / 1000).toFixed(2) + ' MB';
      } else if (size > 1000) {
        return (size / 1000).toFixed(2) + ' KB';
      }
      return size.toString() + ' B';
    },
  },
};
</script>

<style scoped>
.dropzone {
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2rem;
  border: 2px dashed var(--contrast-5);
  background-color: var(--bg);
  transition: border 125ms ease, background-color 125ms ease;
  color: var(--contrast-4);
}

.dropzone-active {
  border-radius: 6px;
  background-color: var(--contrast-1);
}

.upload-list {
  list-style: none;
}

.upload-list li {
  display: flex;
  align-items: center;
}

.upload-list li .bar {
  flex: 100% 1;
}
</style>
